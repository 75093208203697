import client from "@/js/plugins/contentful"

import {
  STORE_ENTRY,
  STORE_ENTRIES,
  STORE_PREVIEW_ENTRY,
  STORE_PREVIEW_ENTRIES,
} from "@/js/store/mutation-types"
import { unionState } from "@common/js/store/helpers"

const state = () => ({
  previews: [],
  entries: [],
})

const actions = {
  async fetchEntryById({ commit }, { entryId }) {
    return client.getEntry(entryId).then((entry) => {
      commit(STORE_ENTRY, [entry.toPlainObject()])
      return entry
    })
  },

  async fetchEntryBySlug({ commit }, { type, slug }) {
    return client
      .getEntries({
        content_type: type,
        "fields.slug[in]": slug,
        include: 5,
      })
      .then((entries) => {
        commit(STORE_ENTRY, [entries.items[0]])
        return entries.items[0]
      })
  },

  async fetchPreviewById({ commit }, { entryId }) {
    return client.getEntry(entryId).then((entry) => {
      commit(STORE_PREVIEW_ENTRY, [entry.toPlainObject()])
      return entry
    })
  },

  async fetchAllEntriesByTagSet({ commit }, { type, tags }) {
    let entries = await client.getEntries({
      content_type: type,
      "metadata.tags.sys.id[all]": String(tags),
      order: "fields.order",
    })
    commit(
      STORE_ENTRIES,
      entries.items.map((o) => {
        return { ...o, id: o.sys.id }
      })
    )
  },

  async fetchAllPreviewsByTagSet(
    { commit },
    {
      type,
      tags,
      select = "sys.id,sys.contentType,fields.title,metadata.tags,fields.description,fields.slug,fields.thumbnailImage,fields.heroImage,fields.author,fields.meta",
      order = "fields.order",
    }
  ) {
    var entries = await client.getEntries({
      content_type: type,
      "metadata.tags.sys.id[all]": String(tags),
      select,
      order,
      include: 5,
    })
    entries = entries.items.map((o) => {
      return { ...o, id: o.sys.id }
    })
    commit(STORE_PREVIEW_ENTRIES, entries)
    return entries
  },

  async fetchAllEntriesByTagInclusion(
    { commit },
    { type, tags, limit = undefined, order = "fields.order" }
  ) {
    let entries = await client.getEntries({
      content_type: type,
      "metadata.tags.sys.id[in]": String(tags),
      order,
    })
    entries = entries.toPlainObject()
    entries = entries.items.map((o) => {
      return { ...o, id: o.sys.id }
    })
    commit(STORE_ENTRIES, entries)
    return entries
  },

  async fetchAllPreviewsByTagInclusion(
    { commit },
    {
      type,
      tags,
      order = "fields.order",
      limit = undefined,
      select = "sys.id,sys.contentType,fields.title, fields.subtitle,metadata.tags,fields.description,fields.slug,fields.thumbnailImage,fields.heroImage,fields.order,fields.author,fields.meta, fields.createdAt",
    }
  ) {
    var entries = await client.getEntries({
      content_type: type,
      "metadata.tags.sys.id[in]": String(tags),
      select,
      order,
      include: 5,
      limit,
    })
    entries = entries.toPlainObject()
    entries = entries.items.map((o) => {
      return { ...o, id: o.sys.id }
    })
    commit(STORE_PREVIEW_ENTRIES, entries)
    return entries
  },

  async fetchAllPreviewsWithByTagInclusion(
    { commit },
    {
      type,
      tags,
      order = "fields.order",
      limit = undefined,
      select = "sys.id,sys.contentType,fields.title, fields.subtitle,metadata.tags,fields.description,fields.slug,fields.thumbnailImage,fields.heroImage,fields.order,fields.author,fields.meta, fields.createdAt",
    }
  ) {
    var entries = await client.getEntries({
      content_type: type,
      "metadata.tags.sys.id[in]": String(tags),
      select,
      order,
      include: 5,
      limit,
    })
    entries = entries.toPlainObject()
    entries = entries.items.map((o) => {
      return { ...o, id: o.sys.id }
    })
    commit(STORE_PREVIEW_ENTRIES, entries)
    return entries
  },
}

const mutations = {
  [STORE_ENTRY](state, entries) {
    state.entries = unionState(state.entries, entries)
  },
  [STORE_ENTRIES](state, entries) {
    state.entries = unionState(state.entries, entries)
  },
  [STORE_PREVIEW_ENTRY](state, entries) {
    state.entries = unionState(state.entries, entries)
  },
  [STORE_PREVIEW_ENTRIES](state, previews) {
    state.previews = unionState(state.previews, previews)
  },
}

const getters = {
  getEntryById: (state) => (entryId) =>
    state.entries.find((e) => e.sys.id === entryId),
  getEntryBySlug: (state) => (slug) =>
    state.entries.find((e) => e.fields.slug === slug),
  getEntriesByTagInclusion: (state) => (tags) =>
    state.entries.filter((e) => {
      const entryTags = e.metadata.tags.map((o) => o.sys.id)
      return entryTags.some((val) => tags.includes(val))
    }),
  getPreviewById: (state) => (entryId) =>
    state.entries.find((e) => e.sys.id === entryId),
  getPreviewsByTagSet: (state) => (tags) =>
    state.previews.filter((e) => {
      const entryTags = e.metadata.tags.map((o) => o.sys.id)
      return tags.every((val) => entryTags.includes(val))
    }),
  getPreviewsByTagInclusion: (state) => (tags) =>
    state.previews.filter((e) => {
      const entryTags = e.metadata.tags.map((o) => o.sys.id)
      return entryTags.some((val) => tags.includes(val))
    }),

  getPreviewsByTagInclusionAndCreatedAt: (state) => (tags) =>
    state.previews
      .filter((e) => {
        const entryTags = e.metadata.tags.map((o) => o.sys.id)
        return entryTags.some((val) => tags.includes(val))
      })
      .sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return (
          new Date(b["fields"]["createdAt"]) -
          new Date(a["fields"]["createdAt"])
        )
      }),
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
